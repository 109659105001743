import PropTypes from 'prop-types';
import classnames from 'classnames';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useStore } from '@/utils/store';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import reRouteTo from '@/utils/api/sessionPass';
import { observer } from 'mobx-react';
import { closeZendesk } from '@/utils/functions/common';
import { localeMap } from '@/config/appConfig';
// import { CustomerService } from '@/components/pages/home/imgs/svgs';
import { MainHeader } from './header';
import { MainFooter } from './footer';
import styles from './MainLayout.module.scss';

const allowDomains = [
  '/eventCenter',
  '/markets',
  '/quizPool',
  '/trade',
  '/news',
  '/helpCenter',
  '/helpCenterApp',
  '/legalCenter',
  '/download',
  '/official-verification',
  '/cs',
  '/',
];

function MainLayout({
  children, className, headProps, hideCustomerService, hideFooter, hideHeader, hideZendesk, pageClassName, ...props
}) {
  const {
    title,
    description = '',
    canonical = 'https://www.bitop.com',
    keyword = 'bitop,Bitop,exchange,交易所',
  } = headProps;
  const { t, i18n } = useTranslation('common');
  const { user } = useStore().auth;
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  // const customerService = useCallback(() => {
  //   let csUrl = 'https://chat.bitopeasy.com?eid=5de2dee8fdc42d3bd5c232c58a41bde1';
  //   if (user) {
  //     const { userId, username } = user;
  //     csUrl += `&showUserId=${userId || ''}&userName=${window.encodeURIComponent(username) || '游客'}`;
  //   }
  //   window.open(csUrl, 'mywin', 'width=500,height=610');
  // }, [user]);

  useEffect(() => {
    const handleRouteChange = () => {
      // console.log('popstate effect', window.history.state)
      if (window.history.state.as !== window.history.state.url) {
        router.push(window.history.state.as); // Reload only when going back，nextJs + browser back/fwd btn toolbar interaction problem
      }
    };
    window.addEventListener('popstate', handleRouteChange);

    const { localStorage } = window;
    const lang = localStorage.getItem('lang');
    if (localeMap[lang] !== i18n) {
      router.replace(router.asPath, router.asPath, { locale: localeMap[lang] }).catch(() => {});
    }
    if (hideZendesk) {
      closeZendesk();
    }
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      const url = router.route.split('/');
      if (!allowDomains.includes(`/${url[1]}`) && user) {
        reRouteTo('/trade/deal/BTCUSDT');
      }
    // eslint-disable-next-line no-empty
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, [router, user]);

  return (
    <>
      {loading && <div className={styles.loading} />}
      <Head>
        <title>{title || t('defaultTitle')}</title>
        <meta property="og:title" content={title || t('defaultTitle')} />
        <meta name="twitter:title" content={title || t('defaultTitle')} />
        <meta property="og:site_name" content={title || t('defaultTitle')} />

        <meta name="description" content={description || t('defaultDescription')} />
        <meta property="og:description" content={description || t('defaultDescription')} />
        <meta name="twitter:description" content={description || t('defaultDescription')} />

        <link rel="canonical" href={canonical || 'https://www.bitop.com'} />
        <meta name="keywords" content={keyword || t('defaultKeywords')} />
      </Head>
      <div className={classnames(styles.container, { [className]: !!className })} {...props}>
        {!hideHeader && (
          <header className={styles.header}>
            <MainHeader />
          </header>
        )}

        <main className={classnames(styles.page, { [pageClassName]: !!pageClassName })}>{children}</main>
        {/* {!hideCustomerService && (
          <div role="presentation" onKeyUp={customerService} className={styles.cs} onClick={customerService}>
            <CustomerService />
          </div>
        )} */}

        {!hideFooter && (
          <footer className={styles.footer}>
            <MainFooter />
          </footer>
        )}
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    canonical: PropTypes.string,
    keyword: PropTypes.string,
  }),
  hideCustomerService: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideZendesk: PropTypes.bool,
  pageClassName: PropTypes.string,
};

MainLayout.defaultProps = {
  className: '',
  headProps: {
    title: '',
  },
  hideCustomerService: false,
  hideHeader: false,
  hideFooter: false,
  hideZendesk: false,
  pageClassName: '',
};

export default observer(MainLayout);
